$primary: #ff5400;
$light: #f6f7f8;
$white: #fff;
$dark: #312620;
$blue: #0a2748;
$text: #6c8eb6;

$sm: 10px;
$md: 15px;
$lg: 20px;
$xl: 25px;
$xl-2: 30px;
$xl-3: 35px;
$xl-4: 40px;
$xl-5: 45px;
$xl-6: 50px;
$large: 100px;
$tab: 768px;
$mobile: 512px;

$font-weights: (
  "regular": 400,
  "medium": 600,
  "bold": 900,
);
/*$numbers: (
  "sm": 10px,
  "md": 15px,
  "lg": 20px,
  "xl": 25px,
  "2xl": 30,
  "3xl": 35px,
  "4xl": 40px,
  "5xl": 45px,
  "6xl": 50px,
  "large": "100px",
);*/
